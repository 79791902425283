import image1 from "../assets/homepage1.svg";
import image2 from "../assets/homepage2.svg";
import image3 from "../assets/homepage3.svg";
import image4 from "../assets/homepage4.svg";
import about1 from "../assets/about1.svg";
import about2 from "../assets/about2.svg";

export const paragraphContent = {
    "HomepageHeader": [
        {
            backgroundColor: "#ffffff",
            title: `Your motoring & insurance world in one easy to access place`,
            largeTitle: true,
            description: [
                {
                    icon: false,
                    des: `The home for all your important insurance information and documents.`
                },
            ],
            buttonLabel: "GET STARTED",
            buttonUrl: "https://app.mypolicyvault.co.uk/Account/Login",
            image: image1,
            imagePosition: "right",
        }
    ],
    "HomepageContent1": [
        {
            backgroundColor: "#f2f9f8",
            title: `When was the last time you struggled to find documents that you needed?`,
            largeTitle: false,
            description: [
                {
                    icon: true,
                    des: `You need your car insurance details but cannot find the documents`
                },
                {
                    icon: true,
                    des: `You got a reminder about your vehicles MOT or Road Fund Licence expiring and you could not find the information needed`
                },
                {
                    icon: true,
                    des: `You or your partner need to make a claim or amend details on the car, house, pet, travel insurance policy`
                },
                {
                    icon: true,
                    des: `You are away from home but you need insurance details urgently`
                },
                {
                    icon: false,
                    des: `My Policy Vault will allow you to arrange, store and share all your important documents and renewal dates in one safe, easy to access place.`
                },
            ],
            image: image2,
            imagePosition: "right",
        }
    ],
    "HomepageContent2": [
        {
            backgroundColor: "#ffffff",
            title: `About our vault`,
            largeTitle: false,
            description: [
                {
                    icon: false,
                    des: `When we started to design My Policy Vault, we wanted to simplify the process of storing, accessing, and sharing important information relating to our day-to-day insurance and motoring needs, along with other information relating to the way we all live our lives.`
                },
                {
                    icon: false,
                    des: `My Policy Vault will not only change the way that we all manage and store our information but will allow you to control how it is used and in turn may save you time and money.`
                },
            ],
            buttonLabel: "READ MORE",
            buttonUrl: "/about",
            image: image3,
            imagePosition: "left",
        }
    ],
    "HomepageContent3": [
        {
            backgroundColor: "#ffffff",
            title: `How do I register?`,
            largeTitle: false,
            description: [
                {
                    icon: false,
                    des: `If you have not already received an invitation to join the My Policy Vault, you can simply click here and register for an account today.`
                },
                {
                    icon: false,
                    des: `The more information you add to the Vault, the greater the benefits you will enjoy.`
                },
                
                
                
            ],
            buttonLabel: "REGISTER",
            buttonUrl: "https://app.mypolicyvault.co.uk/Account/Register",
            image: image4,
            imagePosition: "right",
            banner: true,
        }
    ],
    "AboutHeader": [
        {
            backgroundColor: "#ffffff",
            sectionTitle: "About Us",
            title: `Your motoring & insurance world in one easy to access place`,
            largeTitle: true,
            image: about1,
            imagePosition: "right",
        }
    ],
    "AboutContent1": [
        {
            backgroundColor: "#ffffff",
            title: `Our aim`,
            largeTitle: false,
            description: [
                {
                    icon: false,
                    des: `When we started to design My Policy Vault, we wanted to simplify the
                    process of storing important information relating to our day-to-day
                    insurance and motoring needs, along with other information relating to the
                    way we all live.`
                },
                {
                    icon: false,
                    des: `We are now living in an age where our data is used for daily activities, but
                    when you need the information, it is all over the place or lost!`
                },
                {
                    icon: false,
                    des: `My Policy Vault will not only change the way that we all manage and store
                    our information but will allow you to control how it is used and in turn save
                    you time and money.`
                },
                {
                    icon: false,
                    des: `Since our initial conceptual ideas for motor and home insurance, we have
                    added the ability to store information and documents relating to Pets, Travel,
                    Gadgets and Financial information, such as pensions, wills and Investments,
                    so that we can access and share this from anywhere in the world, with
                    internet access.`
                },
                {
                    icon: false,
                    des: `Once your information is stored in My Policy Vault, you can choose to be
                    notified about important expiry dates for road tax, MOT, and insurance
                    policies. You can also view and purchase products that you might be eligible
                    for by using our “Get Quotes” function, and we can also arrange automated
                    renewal quotes for expiring policies or a quotation at any time, if you so wish.`
                },
                {
                    icon: false,
                    des: `You are in control of your data and by using My Policy Vault you can, quickly
                    and easily, access quotations for insurance you might need.`
                },
                {
                    icon: false,
                    des: `What we do NOT do, is sell your information to third parties or store any
                    banking or payment information, so your money is not at risk.`
                },
                {
                    icon: false,
                    des: `All your important information, accessible from one place with the ability to
                    share the details with joint policyholders or beneficiaries, if necessary.`
                },
            ],
        }
    ],
    "AboutContent2": [
        {
            backgroundColor: "#f2f9f8",
            title: `What is the Policy Vault?`,
            largeTitle: false,
            description: [
                {
                    icon: false,
                    des: `My Policy Vault is a desktop digital platform and App that allows you to
                    manage all of your insurance policies, financial information, vehicles and
                    properties you own or are responsible for, and other categories, in one
                    place, allowing you and anyone else you nominate, to access the
                    information from anywhere with internet access.`
                },
                {
                    icon: false,
                    des: `My Policy Vault is designed to make it easier for you to locate and update
                    your insurance policies, and to allow you to purchase, renew or cancel
                    policies, subscriptions, or memberships from one place. You might even see
                    a type of policy or subscription that you want but did not know it even
                    existed.`
                },
                {
                    icon: false,
                    des: `The more information you upload, the more organised you are and the
                    more you might save. If there is a category or product you would like to
                    add but it is not on our site yet, , suggest it to us and we will try to facilitate
                    your request, if possible.`
                },       
            ],
            buttonLabel: "REGISTER",
            buttonUrl: "https://app.mypolicyvault.co.uk/Account/Register",
            image: about2,
            imagePosition: "right",
            banner: true,
        }
    ],

}